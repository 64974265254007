<template>
  <div class="rounded-lg bg-white text-black w-full md:w-1/3 p-3 flex flex-col">
    <div class="flex align-center my-3">
      <i class="far fa-fw fa-microphone mr-2 self-center"></i>
      <span>Input audio device</span>
    </div>
    <div class="flex items-center">
      <select
        v-model="selectedDeviceId.mic"
        class="block border border-gray-300 w-full rounded-lg px-2 py-2"
      >
        <option
          v-for="option in micOptions"
          :value="option.deviceId"
          :key="option.deviceId"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
    <div class="flex align-center my-3">
      <i class="far fa-fw fa-video mr-2 self-center"></i>
      <span>Input video device</span>
    </div>
    <div class="flex items-center">
      <select
        v-model="selectedDeviceId.cam"
        class="block border border-gray-300 w-full rounded-lg px-2 py-2"
      >
        <option
          v-for="option in camOptions"
          :value="option.deviceId"
          :key="option.deviceId"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
<!--    <div class="flex align-center my-3">
      <i class="far fa-fw fa-volume mr-2 self-center"></i>
      <span>Output audio device</span>
    </div>
    <div class="flex items-center mb-3">
      <select
        v-model="selectedDeviceId.speaker"
        class="block border border-gray-300 w-full rounded-lg px-2 py-2"
      >
        <option
          v-for="option in speakerOptions"
          :value="option.deviceId"
          :key="option.deviceId"
        >
          {{ option.label }}
        </option>
      </select>
    </div>-->
  </div>
</template>

<script>
import state from '../../js/state';

export default {
  name: "Options",
    props: ['selectedDevices'],
  data() {
      return {
          initialized: false,
          allDevices: [],
          selectedDeviceId: {
              mic: null,
              cam: null,
              speaker: null,
          }
      };
  },
  computed: {
    micOptions() {
      return this.allDevices.filter((d) => d.kind === "audioinput");
    },
    camOptions() {
      return this.allDevices.filter((d) => d.kind === "videoinput");
    },
    speakerOptions() {
      return this.allDevices.filter((d) => d.kind === "audiooutput");
    },
  },
  async mounted() {
      if (!navigator?.mediaDevices?.enumerateDevices) {
          alert("Device selection not supported in this browser. Video call will use default audio and video devices.");
          return;
      }

      this.allDevices = await navigator.mediaDevices
          .enumerateDevices()
          .catch(() => alert("Something went wrong"));

      this.$set(this.selectedDeviceId, 'cam', this.selectedDevices.cam?.deviceId)
      this.$set(this.selectedDeviceId, 'mic', this.selectedDevices.mic?.deviceId)
      this.$set(this.selectedDeviceId, 'speaker', this.selectedDevices.speaker?.deviceId)

      this.initialized = true;
  },
  watch: {
    'selectedDeviceId.mic'(deviceId) {
        if(this.initialized) {
            localStorage.selectedMic = deviceId;
            let newDevice = this.allDevices.find(device => device.deviceId === deviceId);
            if(state.selectedDevices.mic?.deviceId !== deviceId) {
                this.$emit('changed');
                this.$set(state.selectedDevices, 'mic', newDevice);
            }
        }
    },
    'selectedDeviceId.cam'(deviceId) {
        if(this.initialized) {
            localStorage.selectedCam = deviceId;
            let newDevice = this.allDevices.find(device => device.deviceId === deviceId);
            if (state.selectedDevices.cam?.deviceId !== deviceId) {
                this.$emit('changed');
                this.$set(state.selectedDevices, 'cam', newDevice);
            }
        }
    },
    'selectedDeviceId.speaker'(deviceId) {
        if(this.initialized) {
            localStorage.selectedSpeaker = deviceId;
            let newDevice = this.allDevices.find(device => device.deviceId === deviceId);
            if (state.selectedDevices.speaker?.deviceId !== deviceId) {
                this.$emit('changed');
                this.$set(state.selectedDevices, 'speaker', newDevice);
            }
        }
    }
  }
};
</script>

<style scoped src="./styles.scss" lang="scss">
</style>
