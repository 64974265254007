<template>
  <Container>
    <Options
      v-on-clickaway="toggleOptions"
      @changed="optionsChanged = true"
      :selected-devices="state.selectedDevices"
      class="absolute bottom-24 left-1/2 z-50"
      v-if="optionsVisible"
    ></Options>

    <div
      class="flex items-center h-full flex-wrap justify-center content-center"
      :class="hideOrAddGaps"
      v-if="!isAnybodySharingScreen()"
    >
      <participant
        v-for="participant in activeParticipants"
        :participant="participant"
        :key="participant.id"
        :active-participants="activeParticipants"
        :is-self="false"
      ></participant>
      <participant
        v-if="
          state.room.localParticipant &&
          activeParticipants.length &&
          activeParticipants.length >= 15
        "
        :participant="state.room.localParticipant"
        :active-participants="activeParticipants"
        :is-self="true"
      ></participant>
    </div>
    <div
      class="flex w-full h-full flex-wrap items-center"
      :class="{
        hidden: activeParticipants.length < 1,
        'gap-3':
          activeParticipants.length >= 1 && activeParticipants.length < 5,
        'gap-1': activeParticipants.length > 4,
        'justify-center content-center': !isAnybodySharingScreen(),
      }"
      v-else
    >
      <participant
        :participant="isAnybodySharingScreen()"
        :active-participants="activeParticipants"
        :is-self="isAnybodySharingScreen() === state.room.localParticipant"
        :is-sharing-screen-full-size="activeParticipants.length"
      ></participant>
    </div>

    <div
      class="
        mt-auto
        w-full
        flex
        items-end
        flex-wrap
        justify-end
        absolute
        bottom-4
        right-6
        z-20
      "
    >
      <div
        class="w-full flex space-x-4 justify-center absolute"
        v-if="!state.room.error"
      >
        <div
          @click="toggleMic"
          v-tooltip.top="
            state.micEnabled
              ? 'Click to disable your microphone'
              : 'Click to enable your microphone'
          "
          class="
            shadow
            flex
            items-center
            justify-center
            w-12
            h-12
            rounded-full
            cursor-pointer
            text-white
          "
          :class="
            state.micEnabled
              ? 'bg-gray-700 hover:bg-gray-600'
              : 'text-gray-850 bg-white'
          "
        >
          <i
            class="far fa-fw fa-sm"
            :class="state.micEnabled ? 'fa-microphone' : 'fa-microphone-slash'"
          ></i>
        </div>
        <div
          @click="toggleCam"
          v-tooltip.top="
            state.camEnabled
              ? 'Click to disable your camera'
              : 'Click to enable your camera'
          "
          class="
            shadow
            flex
            items-center
            justify-center
            w-12
            h-12
            rounded-full
            cursor-pointer
            text-white
          "
          :class="
            state.camEnabled
              ? 'bg-gray-700 hover:bg-gray-600'
              : 'text-gray-850 bg-white'
          "
        >
          <i
            class="far fa-fw fa-sm"
            :class="state.camEnabled ? 'fa-video' : 'fa-video-slash'"
          ></i>
        </div>
        <div
          @click="toggleScreenSharing"
          v-if="!isMobile"
          v-tooltip.top="
            !state.screenShareEnabled
              ? 'Click to share your screen'
              : 'Click to stop sharing your screen'
          "
          class="
            shadow
            flex
            items-center
            justify-center
            w-12
            h-12
            rounded-full
            cursor-pointer
            text-white
          "
          :class="
            !state.screenShareEnabled
              ? 'bg-gray-700 hover:bg-gray-600'
              : 'bg-leaf-500'
          "
        >
          <i class="far fa-fw fa-sm fa-desktop"></i>
        </div>
        <div
          class="
            shadow
            flex
            items-center
            justify-center
            w-12
            h-12
            rounded-full
            cursor-pointer
            text-white
            z-50
          "
          @click="toggleOptions"
          v-if="!isMobile"
          v-tooltip.top="'Click to change your settings'"
          :class="
            !optionsVisible ? 'bg-gray-700 hover:bg-gray-600' : 'bg-leaf-500'
          "
        >
          <i class="far fa-fw fa-sm fa-cog"></i>
        </div>
        <div
          @click="leave"
          v-tooltip.top="'Click to leave the meeting'"
          class="
            shadow
            flex
            items-center
            justify-center
            w-12
            h-12
            bg-red-500
            hover:bg-red-400
            rounded-full
            cursor-pointer
          "
        >
          <i class="far fa-fw fa-sm fa-phone-slash text-white"></i>
        </div>
      </div>
      <div class="z-20">
        <participant
          v-if="isLocalParticipantWithoutSharedScreen"
          :participant="state.room.localParticipant"
          :active-participants="activeParticipants"
          :is-self="true"
        ></participant>
      </div>
    </div>
    <div
      v-if="isAnybodySharingScreen() && activeParticipants.length"
      class="absolute right-0 bottom-0 mr-6 mb-4"
    >
      <div
        class="
          z-20
          flex
          w-344px
          h-full
          flex-wrap
          items-end
          justify-end
          flex-row
          gap-2
        "
      >
        <participant
          v-for="participant in allShownParticipantsWhenScreensharing"
          :participant="participant"
          :key="participant.id"
          :active-participants="activeParticipants"
          :is-sharing-screen-full-size="false"
          :is-self="participant === state.room.localParticipant"
        ></participant>
        <div
          class="
            w-full
            rounded-xl
            text-white
            bg-gray-700
            flex
            justify-between
            mt-10
            p-4
          "
          v-if="Math.ceil(this.activeParticipants.length / this.page_size) > 1"
        >
          <div>
            <i class="far fa-sm fa-user-friends mr-1"></i>
            <span class="text-sm">{{ activeParticipants.length + 1 }}</span>
          </div>
          <div class="flex">
            <div
              @click="decreasePage"
              class="cursor-pointer mr-4 z-50"
              :class="{ 'opacity-30': this.page === 1 }"
            >
              <i class="far fa-xs fa-arrow-left mr-1"></i>
              <span class="text-xs">Previous</span>
            </div>
            <div
              @click="increasePage"
              class="cursor-pointer z-50"
              :class="{
                'opacity-30':
                  this.page ===
                  Math.ceil(this.activeParticipants.length / this.page_size),
              }"
            >
              <span class="text-xs mr-1">Next</span>
              <i class="far fa-xs fa-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import axios from "axios";
//import Pusher from "pusher-js";
import Container from "./components/Container";
import Participant from "./components/Participant";
import state from "./js/state";
import videoProvider from "./js/providers/provider";
import Room from "./js/models/room";
import { isMobile } from "./js/misc";
import Options from "./components/Options";
import helper from "./components/Utils/helper.js";
import { getAvailableDevices } from "./js/mediaDevices";

export default {
  components: {
    Options,
    Container,
    Participant,
  },
  data() {
    return {
      state,
      optionsVisible: false,
      optionsChanged: false,
      isMobile: isMobile(),
      page: 1,
      page_size: 13,
    };
  },
  mounted() {
    let uniqueToken = location.pathname.substring(1);
    // empty url, redirect to Trengo homepage
    if (!uniqueToken) {
      window.location.href = "https://trengo.com";
    }

    this.initDevices();
    // update device list on change
    navigator.mediaDevices.addEventListener("devicechange", () => {
      this.initDevices();
    });

    this.initRoom(uniqueToken);
  },
  methods: {
    reconnect() {
      window.location.reload();
    },
    async initDevices() {
      // load all available devices
      state.availableDevices = await getAvailableDevices();
      // set device from localstorage or default to first device of type
      state.selectedDevices.cam =
        state.availableDevices.find(
          (option) => option.deviceId === localStorage.selectedCam
        ) ||
        state.availableDevices.find((option) => option.kind === "videoinput");
      state.selectedDevices.mic =
        state.availableDevices.find(
          (option) => option.deviceId === localStorage.selectedMic
        ) ||
        state.availableDevices.find((option) => option.kind === "audioinput");
      state.selectedDevices.speaker =
        state.availableDevices.find(
          (option) => option.deviceId === localStorage.selectedSpeaker
        ) ||
        state.availableDevices.find((option) => option.kind === "audiooutput");
    },
    async initRoom(uniqueToken) {
      let userToken;
      if (uniqueToken.startsWith("room-")) {
        // get unique user token for this room
        userToken = localStorage.getItem("user_token_" + uniqueToken);
      } else {
        userToken = uniqueToken;
        // get room token
        let room = (
          await axios.get(`join/${userToken}`).catch(() => {
            alert("Something went wrong");
            window.close();
          })
        )?.data;
        // save user token
        localStorage.setItem("user_token_room-" + room.room_token, userToken);
        // redirect to global room url;
        window.location.href = `https://${window.location.host}/room-${room.room_token}`;
        return;
      }
      // no unique token set, todo create anonymous participant
      if (!userToken) {
        // no unique token set, todo create anonymous participant
        alert("Anonymous participants not allowed.");
        window.close();
        return;
      }
      state.userToken = userToken;
      state.room = new Room(
        (
          await axios.get(`join/${userToken}`).catch(() => {
            alert("Something went wrong");
            window.close();
          })
        )?.data
      );
      await videoProvider.connect(
        state.room.access_token,
        state.room.room_token,
        state.micEnabled,
        state.camEnabled
      );
    },
    toggleOptions() {
      this.optionsVisible = !this.optionsVisible;
      if (
        !this.optionsVisible &&
        this.optionsChanged &&
        confirm(
          "Do you want to reconnect with your chosen audio/video devices?"
        )
      ) {
        this.optionsChanged = false;
        this.reconnect();
      }
    },
    toggleMic() {
      videoProvider.toggleMicrophone();
    },
    toggleCam() {
      videoProvider.toggleCamera();
    },
    toggleScreenSharing() {
      videoProvider.toggleScreenSharing();
    },
    leave() {
      videoProvider.disconnect();
      window.close();
    },
    isAnybodySharingScreen() {
      //can't see shared screen when in mobile
      if (this.isMobile) {
        return;
      }
      return helper.findScreenSharer(this.state.room.participants);
    },
    decreasePage() {
      if (this.page !== 1) {
        this.page--;
      }
    },
    increasePage() {
      if (
        this.page !== Math.ceil(this.activeParticipants.length / this.page_size)
      ) {
        this.page++;
      }
    },
  },
  computed: {
    activeParticipants() {
      return state.room.participants.filter(
        (p) => p.isActive() && p !== state.room.localParticipant
      );
    },
    allShownParticipantsWhenScreensharing() {
      return [
        ...helper.paginate(this.activeParticipants, this.page_size)[
          this.page - 1
        ],
        state.room.localParticipant,
      ];
    },
    isLocalParticipantWithoutSharedScreen() {
      return (
        this.state.room.localParticipant &&
        this.activeParticipants.length &&
        this.activeParticipants.length < 15 &&
        !this.isAnybodySharingScreen()
      );
    },
    hideOrAddGaps() {
        if (this.activeParticipants.length < 1) {
          return 'hidden';
        }
        else if (this.activeParticipants.length >= 1 && this.activeParticipants.length < 5) {
          return 'gap-3';
        }
        else if (this.activeParticipants.length > 4) {
          return 'gap-1';
        }
        return '';
      }
    },
};
</script>

<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
}
.fadeIn {
  animation: fadeIn ease-in-out 100ms;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
