<template>
  <div class="w-full h-full bg-gray-900">
    <div
      ref="activeSpeaker"
      class="w-full h-full flex items-start overflow-y-hidden"
    >
      <div
        v-if="!activeParticipants.length"
        class="text-center text-gray-300 w-full h-full flex flex-col mx-6 my-6"
      >
        <template v-if="state.room.isConnected">
          <participant
            @mute="mute(state.room.localParticipant)"
            v-if="state.room.localParticipant"
            :participant="state.room.localParticipant"
            :active-participants="activeParticipants"
          ></participant>
        </template>
        <template v-else-if="state.room.error">
          <div class="flex flex-col">
            <div class="h-64">
              <div class="center">
                  <p>Something went wrong.</p>
              </div>
            </div>
          </div>
        </template>
          <template v-else>
          <div class="flex flex-col">
            <div class="h-64">
              <div class="center">
                <div class="animation"></div>
              </div>
              <div class="center">
                <avatar
                  class="avatar"
                  v-if="state.room.participant_id"
                  width="120"
                  :color="currentUser.color"
                  :abbr="currentUser.abbr"
                  :image="currentUser.image"
                ></avatar>
              </div>
            </div>
            <p>Connecting...</p>
          </div>
        </template>
      </div>
      <div
        v-else-if="
          state.room.activeSpeaker && !state.room.activeSpeaker.hasVideo && !activeParticipants
        "
        class="
          text-center text-gray-300
          w-full
          z-10
          bg-gray-800
          h-full
          flex
          items-center
          justify-center
        "
      >
        <div class="flex flex-col justify-center items-center">
          <div
            class="inline-block rounded-full overflow-hidden shadow-md"
            style="width: 150px; height: 150px"
          >
            <img
              v-if="state.room.activeSpeaker.image"
              style="max-height: 150px; object-fit: cover"
              :src="state.room.activeSpeaker.image"
              class="w-full h-full"
            />
            <div
              v-else
              class="text-5xl w-full h-full text-white text-bold text-center"
              style="line-height: 150px"
              :style="{ 'background-color': state.room.activeSpeaker.color }"
            >
              {{ state.room.activeSpeaker.abbr }}
            </div>
          </div>
        </div>
        <div class="fixed bottom-0 left-0 ml-3 mb-2 text-sm">
          <i
            class="far fa-fw fa-microphone-slash text-red-500"
            v-if="!state.room.activeSpeaker.hasAudio"
          ></i>
          {{ state.room.activeSpeaker.getDisplayName() }}
        </div>
      </div>
    </div>

    <div class="fixed top-0 right-0 bottom-0 left-0 z-50 flex flex-col p-4 pb-16">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import state from "../../js/state";
import Avatar from "../Avatar";
import Participant from "../Participant";

export default {
  data() {
    return {
      state,
    };
  },
  methods: {},
  computed: {
    activeParticipants() {
      return state.room.participants.filter(
        (p) => p.isActive() && p !== state.room.localParticipant
      );
    },
    currentUser() {
      return state.room.participants.find(
        participant => participant.id === state.room.participant_id
      );
    },
  },
  components: {
    Avatar,
    Participant,
  },
};
</script>
<style scoped src="./styles.scss" lang="scss">
</style>
<style>
.avatar img {
  height: 100%;
  filter: saturate(50%);
}
</style>
