import axios from 'axios';
import Vue from 'vue'
import App from './App.vue'
import './main.scss'
import {VTooltip} from 'v-tooltip'
import { directive as onClickaway } from 'vue-clickaway';

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_TRENGO_API;

Vue.directive('on-clickaway', onClickaway);
Vue.directive('tooltip', VTooltip);

new Vue({
    render: h => h(App),
}).$mount('#app');
