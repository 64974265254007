import {Participant} from "./participant";
//import state from "../state";

export default class Room {
    constructor(roomResource) {
        this.id = roomResource.id;
        this.agency_id = roomResource.agency_id;
        this.room_token = roomResource.room_token;
        this.access_token = roomResource.access_token;
        this.meta = roomResource.meta;
        this.participants = roomResource.participants?.map(p => new Participant(p)) || [];
        this.created_at = roomResource.created_at;
        this.updated_at = roomResource.updated_at;
        this.participant_id = roomResource.participant_id;

        this.isConnected = false;
        this.localParticipant = null;
        this.localScreensharingTrack = null;
        this.localMicrophoneTrack = null;
        this.localCameraTrack = null;
        this.providerRoom = null;
        this.activeSpeaker = null;
        this.error = false;
    }

    setLocalParticipant(participant) {
        this.localParticipant = participant;
        this.localParticipant.setProviderParticipant(participant.providerParticipant);
    }

    setProviderRoom(providerRoom) {
        this.providerRoom = providerRoom;
    }

    onParticipantConnected(/*participantModel*/) {
        // console.log('participant connected', participantModel);
    }

    onParticipantDisconnected(/*participantModel*/) {
        // console.log('participant disconnected', participantModel);
    }

}
