<template>
  <div class="relative" :class="mainContainerClass">
    <div v-if="!participant.providerParticipant" class="justify-center content-center items-center flex w-full h-full">
      <div>Connecting...</div>
    </div>
    <div
      class="
        rounded-2xl
        shadow
        overflow-hidden
        bg-gray-700
        relative
        w-full
        h-full
      "
      :class="innerContainerClass"
    >
      <div
        class="w-full h-full"
        :class="{
          'object-cover': !(
            participant.isSharingScreen && isSharingScreenFullSize
          ),
        }"
        ref="videoContainer"
      ></div>
      <div
        v-if="
          isLocalParticipant
            ? isLocalParticipantShowAvatar()
            : isRemoteParticipantShowAvatar()
        "
        class="
          v-50
          absolute
          w-full
          h-full
          top-0
          flex
          justify-center
          items-center
          bg-gray-700
        "
      >
        <avatar
          class="avatar"
          :width="avatarWidth(isLocalParticipant, activeParticipants.length)"
          :color="participant.color"
          :abbr="participant.abbr"
          :image="participant.image"
        ></avatar>
      </div>
    </div>
    <span
      class="
        participant-label
        absolute
        rounded-full
        text-sm
        z-10
        bottom-0
        left-0
        text-white
        m-2
        truncate
      "
      v-if="!isSharingScreenFullSize"
    >
      <i
        class="far fa-fw fa-microphone-slash text-red-500"
        v-if="isLocalParticipant ? !state.micEnabled : !participant.hasAudio"
      ></i>
      <i class="far fa-wifi-slash" v-if="participant.hasSlowConnection"></i>
      {{ isLocalParticipant ? "You" : participant.getDisplayName() }}
    </span>
    <span
      class="
        participant-label participant-label-round
        absolute
        rounded-full
        text-sm
        z-10
        top-0
        right-0
        text-white
        m-2
      "
      v-if="
        !this.isMobile &&
        participant.isSharingScreen &&
        !isSharingScreenFullSize
      "
    >
      <i class="far fa-presentation"></i>
    </span>
    <span
      class="
        w-max
        participant-label
        absolute
        rounded-full
        text-sm
        z-10
        top-0
        left-1/2
        transform
        -translate-x-1/2
        text-white
        m-2
      "
      v-if="
        !this.isMobile && participant.isSharingScreen && isSharingScreenFullSize
      "
    >
      <i class="far fa-presentation mr-1"></i>
      {{ participant.getDisplayName() }} is presenting
    </span>
  </div>
</template>

<script>
import state from "../../js/state";
import { isMobile } from "../../js/misc";
import Avatar from "../Avatar";
import helper from "../Utils/helper.js";
import eventBus from '../../js/eventBus';

const CONNECTION_TIMEOUT = 10; // in seconds

export default {
  props: [
    "participant",
    "active-participants",
    "is-self",
    "is-sharing-screen-full-size",
  ],
  methods: {
    avatarWidth(isLocalParticipant, participants) {
      if (!this.isAnybodySharingScreen()) {
        if (isLocalParticipant && participants > 0) {
          return 100;
        }
        if (participants <= 1) {
          return window.innerWidth / 3;
        }
        if (participants > 1 && participants <= 4) {
          return window.innerWidth / 6;
        }
        if (participants > 4 && participants <= 9) {
          return window.innerWidth / 9;
        }
        if (participants > 9 && participants <= 16) {
          return window.innerWidth / 12;
        }
        if (participants > 16 && participants <= 25) {
          return window.innerWidth / 15;
        }
        if (participants > 25 && participants <= 36) {
          return window.innerWidth / 18;
        }
        return window.innerWidth / 21;
      } else {
        if (
          this.isSharingScreenFullSize ||
          this.allActiveParticipants.length <= 1
        ) {
          return window.innerWidth / 3;
        } else if (
          this.allActiveParticipants.length <= 5 &&
          this.allActiveParticipants.length > 1
        ) {
          return 100;
        } else if (this.allActiveParticipants.length > 5) {
          return 49;
        }
      }
    },
    isAnybodySharingScreen() {
      //can't see shared screen when in mobile
      return (
        !this.isMobile && helper.findScreenSharer(this.state.room.participants)
      );
    },
    isLocalParticipantShowAvatar() {
      if (this.isAnybodySharingScreen() && this.isSharingScreenFullSize) {
        return false;
      } else if (state.camEnabled) {
        return false;
      } else {
        return true;
      }
    },
    isRemoteParticipantShowAvatar() {
      if (this.participant.hasVideo) {
        return false;
      }
      if (this.participant.isSharingScreen && this.isSharingScreenFullSize) {
        return false;
      }
      return true;
    },
  },
  computed: {
    allActiveParticipants() {
      return state.room.participants.filter((p) => p.isActive());
    },
    mainContainerClass() {
      if (!this.isAnybodySharingScreen()) {
        if (this.activeParticipants.length === 1) {
          return "participant-flex-container-1-per-axis";
        } else if (
          this.activeParticipants.length > 1 &&
          this.activeParticipants.length <= 4
        ) {
          return "participant-flex-container-2-per-axis";
        } else if (
          this.activeParticipants.length > 4 &&
          this.activeParticipants.length <= 9
        ) {
          return "participant-flex-container-3-per-axis";
        } else if (
          this.activeParticipants.length > 9 &&
          this.activeParticipants.length < 16
        ) {
          return "participant-flex-container-4-per-axis";
        } else if (
          this.activeParticipants.length >= 16 &&
          this.activeParticipants.length < 25
        ) {
          return "participant-flex-container-5-per-axis";
        } else if (
          this.activeParticipants.length >= 25 &&
          this.activeParticipants.length < 36
        ) {
          return "participant-flex-container-6-per-axis";
        } else if (this.activeParticipants.length >= 36) {
          return "participant-flex-container-7-per-axis";
        }
      } else {
        if (this.isSharingScreenFullSize) {
          return "participant-screen-shared-full-screen";
        } else if (
          this.allActiveParticipants.length <= 5 &&
          this.allActiveParticipants.length > 1
        ) {
          return "participant-screen-shared-side-window-large";
        } else if (this.allActiveParticipants.length > 5) {
          return "participant-screen-shared-side-window-small";
        }
      }
      return "";
    },
    innerContainerClass() {
      let classes = "";
      if (this.isSelf) {
        classes += "mirror-screen ";
      }

      if (
        this.participant.isDominantSpeaker &&
        this.activeParticipants.length > 2
      ) {
        classes += "border-2 border-leaf-500 ";
      }
      if (
        this.isSelf &&
        this.activeParticipants.length < 15 &&
        this.activeParticipants.length > 0 &&
        !this.isAnybodySharingScreen() &&
        !this.isMobile
      ) {
        classes += "participant-small-container ";
      }

      if (this.isSelf && this.isMobile) {
        classes += "participant-small-container-mobile ";
      }
      if (!this.activeParticipants || this.activeParticipants.length === 0) {
        classes += "participant-fullscreen-container ";
      }
      if (
        this.participant.isSharingScreen &&
        this.isSharingScreenFullSize &&
        this.isSelf
      ) {
        classes += "opacity-25 ";
      }
      return classes;
    },
  },
  data() {
    return {
      state,
      isMobile: isMobile(),
      isLocalParticipant: this.participant === state.room.localParticipant,
    };
  },
  components: {
    Avatar,
  },
  async mounted() {
    if(!this.participant.providerParticipant) {
      await new Promise((resolve, reject) => {
        const timeout = window.setTimeout(() => {
          eventBus.$off('provider-participant-set-'+this.participant.id);
          window.alert('Connection timed out, please try again.');
          console.error('Connection timed out for participant #'+this.participant.id);
          reject();
        }, CONNECTION_TIMEOUT * 1000);
        eventBus.$once('provider-participant-set-'+this.participant.id, () => {
          window.clearTimeout(timeout);
          resolve();
        });
      });
    }

    this.participant.attachMedia(
      this.$refs.videoContainer,
      this.isLocalParticipant ? "video" : null, // local participant shouldn't attach audio
      true,
      !this.isSharingScreenFullSize
    );
  },
};
</script>
<style scoped src="./styles.scss" lang="scss">
</style>
