import {connect, createLocalTracks, LocalVideoTrack} from 'twilio-video';
import {Participant} from "../models/participant";
import state from "../state";
import eventBus from "../eventBus";
import helper from "../../components/Utils/helper.js";
import audio from "../audio"

export default {
    async connect(accessToken, roomName, enableMic = true, enableVideo = true) {
        // options support: https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackConstraints
        const localTracks = await createLocalTracks({
            video: enableVideo ? {
                width: 1280,
                height: 720,
                deviceId: state.selectedDevices?.cam?.deviceId
            } : false,
            audio: enableMic ? {
                deviceId: state.selectedDevices?.mic?.deviceId
            } : false
        }).catch(() => {alert('Something went wrong, did you grant access to your microphone and camera?'); state.room.error=true;});

        if(state.room.error) {
            return;
        }

        let room = await connect(accessToken, {
            name: roomName,
            dominantSpeaker: true,
            tracks: localTracks,
            networkQuality: {
                local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
                remote: 2 // RemoteParticipants' Network Quality verbosity [0 - 3]
            }
        }).catch(e => {console.error('Something went wrong', e); alert('Something went wrong. Is the video call expired? Try starting a new call in Trengo.'); state.room.error=true;});

        if(state.room.error) {
            return;
        }
        //console.log(`Successfully joined a Room: ${room}.`);

        // let the application know we are ready
        state.room.isConnected = true;
        eventBus.$emit('connected');

        // Listen to the "beforeunload" event on window to leave the Room
        // when the tab/browser is being closed.
        window.addEventListener('beforeunload', () => room.disconnect());

        // iOS Safari does not emit the "beforeunload" event on window.
        // Use "pagehide" instead.
        window.addEventListener('pagehide', () => room.disconnect());

        // add providerRoom to room
        state.room.setProviderRoom(room);

        // add ourselves to state
        state.room.setLocalParticipant(this.addParticipant(room.localParticipant));

        // // Change Network Quality verbosity levels after joining the Room
        // state.room.localParticipant.setNetworkQualityConfiguration({
        //     local: 2,
        //     remote: 1
        //   });

        audio.join_self.play().catch(()=>{});

        window.addEventListener('beforeunload', () => audio.leave_self.play().catch(()=>{}));

        // add other participants to state
        room.participants.forEach(p => this.addRemoteParticipant(p));
        room.on('participantConnected', p => {
            audio.join_other.play().catch(()=>{});
            this.addRemoteParticipant(p);
        });

        // remove other participants on disconnect
        room.on('participantDisconnected', participant => {
            let model = this.findParticipant(participant);
            model.status = participant.state;
            state.room.onParticipantDisconnected(model);
            audio.leave_other.play().catch(()=>{});
        });

        // set active speaker
        room.on('dominantSpeakerChanged', participant => {
            let model = this.findParticipant(participant);
            if(model) {
                // unset others
                state.room.participants.forEach(p => p.isDominantSpeaker = false);
                // set current
                model.isDominantSpeaker = true;
                //eventBus.$emit('set-active-speaker', model);
            }
        });

        return room;
    },

    disconnect() {
        return state.room.providerRoom.disconnect();
    },

    addRemoteParticipant(providerParticipant) {
        // bind events for remote participant
        providerParticipant.on('trackUnsubscribed', track => {
            track.detach().forEach(el => el.remove());
        });

        let model = this.addParticipant(providerParticipant);

        eventBus.$emit('participant-connected', model);
        state.room.onParticipantConnected(model);

        // latest connected participant = active speaker:
        //eventBus.$emit('set-active-speaker', model);

        return model
    },

    addParticipant(providerParticipant) {
        let model = this.findParticipant(providerParticipant);

        if(!model) {
            console.warn('participant not found', model, providerParticipant.identity);

            model = new Participant({id: null, providerParticipant: providerParticipant});
            state.room.participants.push(model);
        }

        model.setProviderParticipant(providerParticipant);

        // set active
        model.status = providerParticipant.state;

        // sync state of hasVideo and hasAudio
        model.updateUserStatus();
        providerParticipant.on('trackSubscribed', track => {
            track.on('enabled', () => model.updateUserStatus());
            track.on('disabled', () => model.updateUserStatus());
            model.updateUserStatus();
        });
        providerParticipant.on('trackUnsubscribed', () => {
            model.updateUserStatus();
        });
        providerParticipant.tracks.forEach(publication => {
            publication?.track?.on('enabled', () => model.updateUserStatus());
            publication?.track?.on('disabled', () => model.updateUserStatus());
        });
        providerParticipant.on('trackPublished', () => {
            model.updateUserStatus();
        });
        providerParticipant.on('trackUnpublished', () => {
            model.updateUserStatus();
        });
        providerParticipant.on('networkQualityLevelChanged',  () => {
            model.updateUserStatus()
        });

        return model;
    },

    detachMedia(element, providerParticipant) {
        if(!element || !providerParticipant) {
            return;
        }
        // todo
    },

    attachMedia(element, providerParticipant, kind = null, bindEvents = true, mediaElementClasses = 'w-full h-full absolute fadeIn', onlyForWebcam = false) {
        if(!element || !providerParticipant) {
            return;
        }

        // attach current tracks
        providerParticipant.tracks.forEach(publication => {
            // needs a subscribed track or the local video
            if (publication.isSubscribed || providerParticipant?.identity === state.room.localParticipant?.providerParticipant?.identity) {
                let track = publication.track;
                if(onlyForWebcam && track.name === 'screenshare') {
                    return
                }

                this.attachTrackMedia(track, element, kind, mediaElementClasses);
            }
        });

        // attach new tracks to this element on subscribe
        if(bindEvents) {
            providerParticipant.on('trackSubscribed', track => {
                if(onlyForWebcam && track.name === 'screenshare') {
                    return
                }
                this.attachTrackMedia(track, element, kind, mediaElementClasses);
            });
        }
    },

    attachTrackMedia(track, element, kind, mediaElementClasses, replaceVideo = false) {
        // filter audio/video tracks if necessary
        if(kind && kind !== track.kind) {
            return;
        }

        let v = track.attach();
        v.setAttribute('class', mediaElementClasses);

        // remove all other videos in this element (max 1 video per element)
        if(replaceVideo && track.kind === 'video') {
            element.querySelectorAll('video').forEach(e => e.remove()); // todo: find track and detach? this could be causing memory leak (especially with activeSpeaker enabled)
        }
        // add new video to element
        element.append(v);
    },

    findParticipant(providerParticipant, createRemoteParticipantIfNotFound = false) {
        let participant = state.room.participants.find(p => p.id === parseInt(providerParticipant?.identity));

        if(!participant && createRemoteParticipantIfNotFound) {
            participant = this.addRemoteParticipant(providerParticipant);
        }

        return participant;
    },


    publishVideoStream(stream, trackName) {
        let options = {name: trackName};
        let track = new LocalVideoTrack(stream.getTracks()[0], options);
        state.room.localParticipant.providerParticipant.publishTrack(track);
        return track;
    },

    toggleScreenSharing() {
        // if nobody is sharing his screen
        if (!state.room.localScreensharingTrack && !helper.findScreenSharer(state.room.participants)) {
            try {
                // request stream from browser
                navigator.mediaDevices.getDisplayMedia().then(stream => {
                    // publish track
                    state.room.localScreensharingTrack = this.publishVideoStream(stream, 'screenshare');
                    state.room.localScreensharingTrack.mediaStreamTrack.onended = () => this.toggleScreenSharing();
                    state.screenShareEnabled = true;
                })
            } catch (error) {
                console.error(error)
            }
        } else {
            // stop sharing
            const publication = state.room.localParticipant.providerParticipant.unpublishTrack(state.room.localScreensharingTrack);
            state.room.localParticipant.providerParticipant.emit('trackUnpublished', publication);
            state.room.localScreensharingTrack.stop();
            state.room.localScreensharingTrack = null;
            state.screenShareEnabled = false;
        }
    },

    toggleCamera() {
        state.room.localParticipant.providerParticipant.videoTracks.forEach(publication => {
            if (publication.track.name === 'screenshare') {
                return;
            }
            if(publication.track.isEnabled) {
                publication.track.disable();
                state.camEnabled = false;
            } else {
                publication.track.enable();
                state.camEnabled = true;
            }
        })

        /*if (state.room.localCameraTrack?.isEnabled) {
            state.room.localCameraTrack.disable();
        } else {
            state.room.localCameraTrack.enable();
        }*/
    },

    toggleMicrophone() {
        state.room.localParticipant.providerParticipant.audioTracks.forEach(publication => {
            if(publication.track.isEnabled) {
                publication.track.disable();
                state.micEnabled = false;
            } else {
                publication.track.enable();
                state.micEnabled = true;
            }
        })
    },
}
