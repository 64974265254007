import videoProvider from '../providers/provider';
import vue from 'vue';
import state from "../state";
import eventBus from '../eventBus';

export class Participant {
    constructor(participantResource) {
        this.id = participantResource.id;
        this.status = participantResource.status;
        this.name = participantResource.name;
        this.abbr = participantResource.abbr;
        this.color = participantResource.color;
        this.image = participantResource.image;

        this.providerParticipant = participantResource?.providerParticipant;
        this.attachedElements = [];
        this.participantElement = null;
        this.isDominantSpeaker = false;
        this.hasVideo = false;
        this.hasAudio = false;
        //...
    }

    isActive() {
        return this.status === 'connected';
    }

    attachMedia(element, kind, isParticipantElement = false, onlyForWebcam) {
        if(!this.providerParticipant) {
            return;
        }

        if(isParticipantElement) {
            this.participantElement = element;
        }

        let cssClasses = 'w-full h-full absolute fadeIn bg-black';
        if (onlyForWebcam) {
            cssClasses +=' object-cover';
        }

        videoProvider.attachMedia(element, this.providerParticipant, kind, true, cssClasses, onlyForWebcam);
    }

    detachMedia(element) {
        if(!this.providerParticipant) {
            return;
        }

        let elIndex = this.attachedElements.indexOf(element);
        this.attachedElements.splice(elIndex, 1);

        videoProvider.detachMedia(element, this.providerParticipant);
    }

    setProviderParticipant(providerParticipant) {
        this.providerParticipant = providerParticipant;
        eventBus.$emit('provider-participant-set-'+this.id, this);
    }

    updateUserStatus() {
        vue.set(this, 'hasAudio', this.getHasAudio());
        vue.set(this, 'hasVideo', this.getHasVideo());
        vue.set(this, 'isSharingScreen', this.getIsSharingScreen());
        vue.set(this, 'hasSlowConnection', this.getHasSlowConnection());
    }

    getHasVideo() {
        if(this === state.room.localParticipant) {
            return (state.camEnabled || state.screenShareEnabled);
        }

        // no video tracks
        if(!this.providerParticipant.videoTracks.size) {
            return false;
        }

        // find enabled video track
        let enabledVideo = false;
        this.providerParticipant.videoTracks.forEach(publication => {
            if(publication.isSubscribed && publication.isTrackEnabled && publication.track.name !== 'screenshare') {
                enabledVideo = true;
            }
        });

        return enabledVideo;
    }

    getHasAudio() {
        if(this === state.room.localParticipant) {
            return state.micEnabled;
        }

        // no video tracks
        if(!this.providerParticipant.audioTracks.size) {
            return false;
        }

        // find enabled video track
        let enabledAudio = false;
        this.providerParticipant.audioTracks.forEach(publication => {
            if(publication.isSubscribed && publication.isTrackEnabled) {
                enabledAudio = true;
            }
        });

        return enabledAudio;
    }

    getIsSharingScreen() {
        return this.providerParticipant.videoTracks.size > 1;
    }

    getHasSlowConnection() {
        return false; //this.providerParticipant.networkQualityLevel <= 3;
    }

    getDisplayName() {
        return this.name || 'Anonymous';
    }
}
