var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative",class:_vm.mainContainerClass},[(!_vm.participant.providerParticipant)?_c('div',{staticClass:"justify-center content-center items-center flex w-full h-full"},[_c('div',[_vm._v("Connecting...")])]):_vm._e(),_c('div',{staticClass:"\n      rounded-2xl\n      shadow\n      overflow-hidden\n      bg-gray-700\n      relative\n      w-full\n      h-full\n    ",class:_vm.innerContainerClass},[_c('div',{ref:"videoContainer",staticClass:"w-full h-full",class:{
        'object-cover': !(
          _vm.participant.isSharingScreen && _vm.isSharingScreenFullSize
        ),
      }}),(
        _vm.isLocalParticipant
          ? _vm.isLocalParticipantShowAvatar()
          : _vm.isRemoteParticipantShowAvatar()
      )?_c('div',{staticClass:"\n        v-50\n        absolute\n        w-full\n        h-full\n        top-0\n        flex\n        justify-center\n        items-center\n        bg-gray-700\n      "},[_c('avatar',{staticClass:"avatar",attrs:{"width":_vm.avatarWidth(_vm.isLocalParticipant, _vm.activeParticipants.length),"color":_vm.participant.color,"abbr":_vm.participant.abbr,"image":_vm.participant.image}})],1):_vm._e()]),(!_vm.isSharingScreenFullSize)?_c('span',{staticClass:"\n      participant-label\n      absolute\n      rounded-full\n      text-sm\n      z-10\n      bottom-0\n      left-0\n      text-white\n      m-2\n      truncate\n    "},[(_vm.isLocalParticipant ? !_vm.state.micEnabled : !_vm.participant.hasAudio)?_c('i',{staticClass:"far fa-fw fa-microphone-slash text-red-500"}):_vm._e(),(_vm.participant.hasSlowConnection)?_c('i',{staticClass:"far fa-wifi-slash"}):_vm._e(),_vm._v(" "+_vm._s(_vm.isLocalParticipant ? "You" : _vm.participant.getDisplayName())+" ")]):_vm._e(),(
      !this.isMobile &&
      _vm.participant.isSharingScreen &&
      !_vm.isSharingScreenFullSize
    )?_c('span',{staticClass:"\n      participant-label participant-label-round\n      absolute\n      rounded-full\n      text-sm\n      z-10\n      top-0\n      right-0\n      text-white\n      m-2\n    "},[_c('i',{staticClass:"far fa-presentation"})]):_vm._e(),(
      !this.isMobile && _vm.participant.isSharingScreen && _vm.isSharingScreenFullSize
    )?_c('span',{staticClass:"\n      w-max\n      participant-label\n      absolute\n      rounded-full\n      text-sm\n      z-10\n      top-0\n      left-1/2\n      transform\n      -translate-x-1/2\n      text-white\n      m-2\n    "},[_c('i',{staticClass:"far fa-presentation mr-1"}),_vm._v(" "+_vm._s(_vm.participant.getDisplayName())+" is presenting ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }