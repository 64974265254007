import Room from "./models/room";

export default {
    room: new Room({}),
    audioEnabled: true,
    micEnabled: true,
    camEnabled: true,
    selectedDevices: {
        mic: null,
        cam: null,
        speaker: null,
    },
    availableDevices: [],
    screenShareEnabled: false,
    userToken: null,
    pusher: null,
    pusherChannel: null,
}
