const audio = {
    ringing: new Audio('https://trengo.s3.eu-central-1.amazonaws.com/videocalling/audio/ringing.mp3'),
    join_self: new Audio('https://trengo.s3.eu-central-1.amazonaws.com/videocalling/audio/join_self.mp3'),
    join_other: new Audio('https://trengo.s3.eu-central-1.amazonaws.com/videocalling/audio/join_other.mp3'),
    leave_self: new Audio('https://trengo.s3.eu-central-1.amazonaws.com/videocalling/audio/leave_self.mp3'),
    leave_other: new Audio('https://trengo.s3.eu-central-1.amazonaws.com/videocalling/audio/leave_other.mp3'),
}

audio.ringing.volume = .3;
audio.join_self.volume = .3;
audio.join_other.volume = .3;
audio.leave_self.volume = .3;
audio.leave_other.volume = .3;

export default audio;