<script type="text/babel">
    export default {
        props: {
            color: null,
            abbr: {
                type: String,
                default: ''
            },
            image: {
                type: String,
                default: ''
            },
            width: {
                default: 40
            },
            fontSize: {
                default: '14px'
            },
            border: {
                type: Boolean,
                default: false
            }
        },
        filters: {
            upper: function (value) {
                if (!value) {
                    return;
                }

                return value.toUpperCase();
            }
        }
    }
</script>

<template>
    <span class="select-none circle inline-block-40px avatar flex-shrink-0"  :class="{ 'border-2 green-lighter': border }" :style="{'font-size': fontSize, width:width+'px', height:width+'px', 'line-height':width+'px', color:'white', background: color}" v-if="image == null">
        {{ abbr | upper }}
    </span>
    <span class="select-none circle avatar inline-block-40px flex-shrink-0" :class="{'border-2 border-green-lighter': border}" v-else :style="{  width:width+'px', height:width+'px', 'line-height': 1 }">
        <img :src="image" style="border-radius:100%" />
    </span>
</template>

<style scoped src="./styles.scss" lang="scss">
</style>